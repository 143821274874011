<template>
  <div class="card">
    <div class="card-icon">
      <i :class="icon"></i>
    </div>
    <div class="card-text">
      <span class="number">{{ text }}</span>
      <span class="text-description">{{ title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashcard",
  props: {
    title: {
      type: String,
      default: "Balance",
    },
    text: {
      default: "500",
    },
    icon: {
      default: "fa-solid fa-wallet",
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
