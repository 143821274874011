<template>
  <div class="dashboard-main-page">
    <div ref="slide" class="stats-cards scroll">
      <DashCard
        title="Balance"
        :text="`₦${user && user.balance ? user.balance : 0}`"
      />
      <DashCard
        title="Referals"
        :text="user && user.totalReferals && user.totalReferals"
        icon="fa-solid fa-network-wired"
      />
      <DashCard
        title="Total Orders"
        icon="fa-solid fa-cart-shopping"
        :text="user && user.totalReferals && user.totalReferals"
      />
      <DashCard
        icon="fa-solid fa-ticket"
        title="Total Tickets"
        :text="user && user.totalReferals && user.totalReferals"
      />
      <!-- <div class="nav-buttons">
        <button @click="slide('left')">Prev</button>
        <button @click="slide('right')">Next</button>
      </div> -->
    </div>
    <div class="dashboard-container">
      <div class="order-section">
        <form v-on:submit.prevent class="login-form">
          <div class="input-wrapper">
            <label for="category">Category</label>
            <select id="category" name="category" class="order-select input">
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
            </select>
          </div>
          <div class="input-wrapper">
            <label for="service">Service</label>
            <select name="service" id="service" class="order-select input">
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
              <option value="hello">Wow</option>
            </select>
          </div>
          <div class="description-wrapper">
            <label>Description</label>
            <div>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis
              provident modi tempore nihil ipsam, eius consequatur debitis
              sapiente laborum iure minima ex unde corrupti reprehenderit
              adipisci dolore beatae voluptatibus, quibusdam repudiandae?
              Accusantium omnis pariatur tempore perspiciatis velit labore atque
              vitae, eius ex? Suscipit eligendi veniam at tempore, minima iste
              aliquid.
            </div>
          </div>

          <div class="input-wrapper">
            <label for="link">Link</label>
            <input id="link" name="link" class="input" placeholder="Link" />
          </div>
          <div class="input-wrapper">
            <label for="comments">Comments</label>
            <textarea
              id="comments"
              name="Comments"
              class="input"
              placeholder="Comments"
            >
            </textarea>
          </div>
          <div class="input-wrapper double">
            <div class="d-wrapper">
              <label for="quantity">Quantity</label>
              <input
                name="quantity"
                id="quantity"
                class="input"
                placeholder="Quantity"
              />
            </div>
            <div class="d-wrapper">
              <label for="charge">Charge</label>
              <input
                name="charge"
                id="charge"
                class="input"
                placeholder="Charge"
              />
            </div>
          </div>
          <div class="drip-field-w">
            <div class="drip-field">
              <label for="drip-f">Drip Field</label>
              <input type="checkbox" name="drip-f" id="drip-f" />
            </div>
            <div class="input-wrapper double">
              <div class="d-wrapper">
                <label for="drip-field">Runs : Interval (minutes)</label>
                <input
                  name="drip-field"
                  id="drip-field"
                  class="input"
                  placeholder="Interval (minutes)"
                />
              </div>
              <div class="d-wrapper">
                <label for="t-q">Total quantity</label>
                <input
                  name="total-q"
                  id="t-q"
                  class="input"
                  placeholder="Total Quantity"
                />
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" class="btn">Place Order</button>
          </div>
        </form>
      </div>
      <div class="faq-s">
        <h5 class="faq-head">Please Read These Order Related Faq's</h5>
        <div class="questions">
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> Can I cancel an order? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              This is automatic server so after place an order we can't edit,
              change, cancel it even if you insert wrong link. Order with wrong
              link can mark as completed by server without delivery and no
              cancel / refund for it.Sometimes our server can find out wrong
              link and cancel it but this is very rare.
            </div>
          </div>
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> What is drip feed? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              Drip Feed is a service that we are offering so you would be able
              to put the same order multiple times automatically. Example: let's
              say you want to get 1000 likes on your Instagram Post but you want
              to get 100 likes each 30 minutes. So, here is how to do that?
              Link: Your Post Link Quantity: 100 Runs: 10 (as you want to run
              this order 10 times, if you want to get 2000 likes, you will run
              it 20 times, etc…) Interval: 30 (because you want to get 100 likes
              on your post each 30 minutes, if you want each hour, you will put
              60 because the time is in minutes) P.S: Never order more quantity
              than the maximum which is written on the service name (Quantity x
              Runs) Example: if the service's max is 4000, you don’t put
              Quantity: 500 and Run: 10, because total quantity will be 500x10 =
              5000 which is bigger than the service max (4000). Also never put
              the Interval below the actual start time (some services need 60
              minutes to start, don’t put Interval less than the service start
              time or it will cause a fail in your order).
            </div>
          </div>
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> What is start count? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              Start count is the number of current followers, likes, views the
              page / post have which you order. Example: Your Instagram page
              have 1000 followers now. If you order followers service on our
              website then the order start count will be 1000
            </div>
          </div>
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> What is partial order status? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              Partial Status is when we partially refund the remains of an
              order. Sometimes for some reasons our server is unable to deliver
              a full order, so we refund / adjust you the remaining undelivered
              amount balance. Example: You bought an order with quantity 5000
              and charges 5$, let's say we delivered 3000 and the remaining 2000
              we couldn't deliver, then we will "Partial" the order and refund
              you the remaining 2000 (2$ in this example). This partial refunded
              amount will automacally adjust to your user balance. Many new
              customer think the money has not added to their balance. To them,
              please calaulate your all order cost and fund adding history and
              you will get it. If an order got partial it's not eligible for any
              refill guarantee, you can order again using other server.
            </div>
          </div>
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> Why my order canceled? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              Sometimes server got problem and unable to take order and it
              cancel the order. If any order got cancel, it automatically refund
              / adjust the money for that orderto your account balance. Check
              the canceled order cost is zero. You can check your account
              balance too. In this case just order again.
            </div>
          </div>
          <div class="question">
            <div @click="handleAccordion" class="question-main pointer">
              <span class="text"> What is partial order status? </span>
              <i class="fa-solid fa-arrow-down"></i>
            </div>
            <div class="panel">
              Partial Status is when we partially refund the remains of an
              order. Sometimes for some reasons our server is unable to deliver
              a full order, so we refund / adjust you the remaining undelivered
              amount balance. Example: You bought an order with quantity 5000
              and charges 5$, let's say we delivered 3000 and the remaining 2000
              we couldn't deliver, then we will "Partial" the order and refund
              you the remaining 2000 (2$ in this example). This partial refunded
              amount will automacally adjust to your user balance. Many new
              customer think the money has not added to their balance. To them,
              please calaulate your all order cost and fund adding history and
              you will get it. If an order got partial it's not eligible for any
              refill guarantee, you can order again using other server.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashCard from "../../../components/dashboard-card/index.vue";
import { mapState } from "vuex";
export default {
  name: "Dashboard",
  components: {
    DashCard,
  },
  methods: {
    slide(direction) {
      const slide = this.$refs.slide;
      let scrollCompleted = 0;
      var slideVar = setInterval(function () {
        if (direction == "left") {
          slide.scrollLeft -= 10;
        } else {
          slide.scrollLeft += 10;
        }
        scrollCompleted += 10;
        if (scrollCompleted >= 100) {
          window.clearInterval(slideVar);
        }
      }, 50);
    },
    handleAccordion(e) {
      e.target.classList.toggle("active");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
